import React, { lazy } from "react";
import Login from "../screens/login";

const Logout = lazy(() => import("../screens/logout"));
const Home = lazy(() => import("../screens/home"));
const PollingForm = lazy(() => import("../components/polling/form"));
const Registration = lazy(() => import("../screens/registration"));
const NotFound = lazy(() => import("../screens/404"));
const Members = lazy(() => import("../screens/modules/members/list"));
const MemberDetails = lazy(() => import("../screens/modules/members/details"));
const MemberForm = lazy(() => import("../screens/modules/members/form"));

interface RouteType {
    path: string;
    component: React.ReactElement;
    name: string;
    protected: boolean;
}

const routes: RouteType[] = [
    {
        path: "/",
        component: <Home />,
        name: "Login Screen",
        protected: true,
    },
    {
        path: "/login",
        component: <Login />,
        name: "Login Screen",
        protected: false,
    },
    {
        path: "/poll/:poll_id",
        component: <PollingForm />,
        name: "Polling Form",
        protected: true,
    },
    {
        path: "/logout",
        component: <Logout />,
        name: "Logout",
        protected: true,
    },
    {
        path: "/registration/:club_domain",
        component: <Registration />,
        name: "Registration",
        protected: false
    },
    {
        path: "/404",
        component: <NotFound />,
        name: "Not Found",
        protected: false
    },
    {
        path: "/members",
        component: <Members />,
        name: "Members",
        protected: true
    },
    {
        path: "/members/add",
        component: <MemberForm />,
        name: "Members",
        protected: true
    }, {
        path: "/members/:familyId",
        component: <MemberDetails />,
        name: "Members",
        protected: true
    }, {
        path: "/members/:familyId/:memberId/modify",
        component: <MemberForm />,
        name: "Members",
        protected: true
    }
];

export default routes;
