import React, { Component } from "react";

interface ErrorBoundaryProps {
    children: React.ReactElement
    fallback: React.ReactElement
}

class ErrorBoundary extends Component<ErrorBoundaryProps, any> {
    state = {
        hasError: false
    };

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if(this.state.hasError) {
            return this.props.fallback;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
