import React, {useEffect, useState} from "react";
import {Container, Row, Col, Input, FormGroup, Button, Form, Label, Card, CardBody, FormText} from "reactstrap";
import logo from "../../assets/images/logo.png";
import taLogo from "../../assets/images/ta-logo.png";
import {ErrorMessage, Field, FieldProps, Formik} from "formik";
import {useSelector, useDispatch} from "react-redux";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import RootStore from "../../store/store.types";
import {LoginPayload, toggleAuthenticationState, updateUserDetails} from "./login.slice";
import {LoginService} from "../../services/login";
import {toast} from "react-toastify";
import {setCookie} from "../../helpers/CookieWrapper";
import {getClubDetails} from "../home/home.slice";
import ErrorBoundary from "../../helpers/ErrorBoundary";
import ErrorBoundaryComponent from "../../components/UI/error-boundary-component";

export interface LoginForm {
    mobile: string
    otp: string
    club: string
}

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authenticatedState = useSelector((state: RootStore) => state.login.user.authenticated);

    const [showOTPInput, setShowOTPInput] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [countDownTimer, setCountDownTimer] = useState(0);

    useEffect(() => {
        if (authenticatedState) {
            navigate("/");
        }
    }, [authenticatedState, navigate]);

    const LoginFormSchema = Yup.object().shape({
        mobile: Yup.string().matches(/^[6-9]\d{9}$/, {
            message: "Please enter a valid number.",
            excludeEmptyString: false
        }).required("Please enter your mobile number."),
        club: Yup.string().required('Required'),
        otp: Yup.number().typeError("Please enter a valid number")
    });

    const formSubmitHandler = async (values: LoginForm) => {
        if (countDownTimer > 0) {
            return false;
        }

        let payload: LoginPayload = {
            username: `${values.mobile}@${values.club}.club`,
            otp: "",
            type: "1"
        };

        if (showOTPInput) {
            payload["otp"] = values.otp;
            payload["type"] = "2";
        }

        setFormLoading(true);
        await LoginService(payload).then(res => {
            setShowOTPInput(true);
            const token = res?.token;
            if (token) {
                if(!res?.admin) {
                    const notify = () => toast.error("Restricted Access");
                    notify();
                    setShowOTPInput(false);
                    return false;
                }

                setCookie("token", token);
                dispatch(toggleAuthenticationState(true));
                dispatch(updateUserDetails(res));
                dispatch(getClubDetails(res?.regId || -1) as any);
            }
        }).catch(err => {
            const notify = () => toast.error(err || "Invalid username or password");
            notify();
        });
        setFormLoading(false);
    };

    useEffect(() => {
        if (countDownTimer > 0) {
            const interval = setInterval(() => {
                setCountDownTimer(countDownTimer - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [countDownTimer]);

    return <ErrorBoundary fallback={<ErrorBoundaryComponent />}>
        <Container className={`d-flex align-items-center w-100 h-100`}>
            <div className={`w-100`}>
                <Row>
                    <Col xl={{
                        offset: 3,
                        size: 6
                    }} md={{
                        offset: 3,
                        size: 6
                    }}>
                        <Card>
                            <CardBody>
                                <div className={`mx-auto text-center mt-3`}>
                                    <img src={logo} alt={`Logo`} width={125}/>
                                </div>
                                <Formik
                                    initialValues={{
                                        mobile: "",
                                        club: "",
                                        otp: ""
                                    }}
                                    validationSchema={LoginFormSchema}
                                    onSubmit={formSubmitHandler}>
                                    {({handleSubmit, values, setFieldValue}) => {
                                        return (
                                            <Form onSubmit={handleSubmit} className={`mt-5`} name={`login-form`}>
                                                <Field name="mobile">
                                                    {({
                                                          field,
                                                          meta,
                                                      }: FieldProps) => (
                                                        <FormGroup>
                                                            <Label for="mobile">Mobile</Label>
                                                            <div
                                                                className={`d-flex align-items-center justify-content-center`}>
                                                                <Input type="tel" id="mobile"
                                                                       placeholder="Enter your registered mobile"
                                                                       maxLength={10}
                                                                       disabled={showOTPInput}
                                                                       autoFocus
                                                                       invalid={(meta.touched && meta.error && true) || false}
                                                                       {...field}
                                                                />
                                                                <span className={`mx-2`}>
                                                                @
                                                            </span>
                                                                <Input type={`text`}
                                                                       id={`club`}
                                                                       placeholder={`Club`}
                                                                       value={values.club}
                                                                       onChange={e => {
                                                                           setFieldValue("club", e.target.value);
                                                                       }}
                                                                />
                                                                <span className={`ms-2`}>
                                                                .club
                                                            </span>
                                                            </div>
                                                            {meta.touched && meta.error && (
                                                                <ErrorMessage name="mobile" component="div">
                                                                    {(msg) => (
                                                                        <small className={"text-danger"}>
                                                                            {msg}
                                                                        </small>
                                                                    )}
                                                                </ErrorMessage>
                                                            )}
                                                            {!showOTPInput && <div>
                                                                <FormText>
                                                                    A One Time Password (OTP) will be sent to your
                                                                    registered mobile number.
                                                                </FormText>
                                                            </div>}
                                                            {showOTPInput &&
                                                                <FormText className={`d-block text-end`}
                                                                          onClick={() => {
                                                                              setShowOTPInput(false);
                                                                          }}>
                                                                    <span
                                                                        className={`text-primary`}>Change Number?</span>
                                                                </FormText>}
                                                        </FormGroup>
                                                    )}
                                                </Field>
                                                {showOTPInput && <Field name="otp">
                                                    {({
                                                          field,
                                                          meta,
                                                      }: FieldProps) => (
                                                        <FormGroup>
                                                            <Label for="otp">OTP</Label>
                                                            <Input type="tel" id="otp"
                                                                   placeholder="Enter your OTP"
                                                                   maxLength={6}
                                                                   autoFocus
                                                                   invalid={(meta.touched && meta.error && true) || false}
                                                                   {...field} />
                                                            {meta.touched && meta.error && (
                                                                <ErrorMessage name="otp" component="div">
                                                                    {(msg) => (
                                                                        <small className={"text-danger"}>
                                                                            {msg}
                                                                        </small>
                                                                    )}
                                                                </ErrorMessage>
                                                            )}
                                                        </FormGroup>
                                                    )}
                                                </Field>}
                                                <FormGroup className={`mt-5`}>
                                                    <Button color={`primary`} block type={`submit`}
                                                            disabled={formLoading}>
                                                        {!formLoading ? (!showOTPInput ? 'Request OTP' : 'Login') : ""}
                                                        {formLoading && "Loading..."}
                                                    </Button>
                                                </FormGroup>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                                <div className={`text-center mt-5`}>
                                    <p><small>
                                        Created by
                                    </small></p>
                                    <a href={`https://theappsolutes.com`}>
                                        <img src={taLogo} alt={`The Appsolutes`} width={54}/>
                                    </a>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    </ErrorBoundary>
};

export default Login;
